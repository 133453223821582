.root2{
    color: rgb(255, 194, 194);
    background-color: #0A7983;
    border-radius: 1rem;
}

.root2-mobile{
    color: rgb(255, 194, 194);
    background-color: #0A7983;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.toolbar{
    color: rgb(44, 171, 180);
    background-color:#95F8F1;
    border-radius: 1rem;
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;   
}

.toolbar-mobile{
    color: rgb(1,239,255);
    background-color: #95F8F1;
    border-radius: 1rem;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}

.toolbarText {
    color: #0A7983; 
}
.toolbarText-mobile {
    color: #0A7983; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    margin: auto;
}

.toolbarText2 a {
    color: #0A7983;
    background-color: #95F8F1;
}

.dropdown-menu.show {
    background-color: #95F8F1;
    color: #0A7983;  
}

.basic-nav-dropdown {
    color: #0A7983;
}

.btn-toolbar-dropdown {
    border: none;
    white-space: nowrap;
}

.brand {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
}

