html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.root {
  display: flex;
  justify-content: center;
  color: #0A7983;
  background-color: #0A7983;
  height: 100%;
  width: 100%;
  font-family: "Lucida Console", "Courier New", monospace;
}
