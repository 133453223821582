.emailDialog {
    background-color: black;
    font-family: "Lucida Console", "Courier New", monospace;
}

.emailContent {
    background-color: #0A7983;
    border-color: #95F8F1;
    font-family: "Lucida Console", "Courier New", monospace;
}

.emailContent-mobile {
    background-color: rgb(2,192,241);
    border-color: rgb(207,58,165);
    font-family: "Lucida Console", "Courier New", monospace;
    max-width: 75vw;
    max-height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto; 
}

.text-style, input-group-text {
    text-align: center;
    font-size: 14pt;
    font-family: "Lucida Console", "Courier New", monospace;
    color: #95F8F1;
}

.text-style-mobile, input-group-text {
    overflow:auto;
    text-align: center;
    font-size: 8pt !important;
    width: 100%;
    font-family: "Lucida Console", "Courier New", monospace;
    color: #95F8F1;
}

.input-group-mobile {
display: flex;
flex-direction: column !important;
justify-content: center;
align-items: center;
margin: 1pt;
}

.input-group {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center; 
    margin: 1pt;
}