@import url(https://fonts.googleapis.com/css?family=Montserrat);

html, body{
  height: 100%;
  font-weight: 800;
  margin: 0;
  padding: 0;
}

body{
  background: ##0A7983;
  font-family: Arial;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex; 
  height: 80%;
  width: 80%;
  align-items: center;
}

svg {
    display: block;
    font: 10.5em 'Montserrat';
    width: 100%;
    height: 20%;
    margin: 0 auto;
}

.text-copy {
    fill: none;
    stroke: white;
    stroke-dasharray: 6% 29%;
    stroke-width: 5px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 5.5s infinite linear;
}

.text-copy:nth-child(1){
  stroke: #63E6E1;
  animation-delay: -1;
}

.text-copy:nth-child(2){
  stroke: #63E6E1;
  animation-delay: -2s;
}

.text-copy:nth-child(3){
  stroke: #95F8F1;
  animation-delay: -3s;
}

.text-copy:nth-child(4){
  stroke: #63E6E1;
  animation-delay: -4s;
}

.text-copy:nth-child(5){
  stroke: #95F8F1;
  animation-delay: -5s;
}

@keyframes stroke-offset{
  100% {stroke-dashoffset: -35%;}
}

.bio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    max-width: 33%;
    font-family:'Courier New', Courier, monospace;
    font-weight: bold;
    color: #95F8F1;
}

.bio-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  max-width: 60%;
  font-family:'Courier New', Courier, monospace;
  font-weight: bold;
  color: #95F8F1;
}

.homeImage {
    height: 30%;
    width: 30%;
    border-style: solid;
    border-radius: 5%;
    border-color: #95F8F1;
    border-width: 0.25rem;
}

.homeImage-mobile{
  height: 100%;
    width: 100%;
    border-style: solid;
    border-radius: 5%;
    border-color: #95F8F1;
    border-width: 0.25rem;
}

.emailButton {
  background-color: #95F8F1;
  border: 0 solid #E5E7EB;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75rem;
  padding: .75rem 1.65rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 100%;
  max-width: 460px;
  position: relative;
  cursor: pointer;
  transform: rotate(-2deg);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.emailButton:focus {
  outline: 0;
}



.emailButton:hover:after {
  bottom: 2px;
  left: 2px;
}

.icon{
    height:30%;
    width:30%;
}


.emailButton2 {
  background-color: #95F8F1;
  border: 0 solid #E5E7EB;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75rem;
  padding: .75rem 1.65rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 100%;
  max-width: 460px;
  position: relative;
  cursor: pointer;
  transform: rotate(2deg);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 5pt;
}