body, html {
    height: 100%;
	width: 100%;
	font-family: "Oswald", sans-serif;
	background-color: #0A7983;
	color: hsl(0, 0%, 10%);
}
h1 {
	text-align: center;
	color: aliceblue;
	font-family:'Courier New', Courier, monospace;
	font-size: 2rem;
	font-weight: bold;
}

.root{
    display:flex;
    height: 100%;
}

.festivalText {
	color: black;
	font-size: 16pt;
}

.festivalText-mobile{
	color: black;
	font-size: 12pt;
}

.dateText {
	color: rgb(65, 0, 85);
	font-size: 14pt;
}

.dateText-mobile{
	color: rgb(65, 0, 85);
	font-size: 10pt;
}

.img {
	height:35%;
	width: 35%;
}

.img-mobile {
	height: 100%;
	width: 100%;
}

.list-group{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 50vw;
}

.list-group-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 2%;
}

.header-mobile {
	font-size: 18pt;
}

.header-desktop {
	font-size: 24pt;
}