html, body{
    height: 100%;
    width: 100%;
}

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
}

.carousel {
    height: 85vh;
    width: 75vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: 2rem;
}

.carousel-mobile{
    height: 60vh;
    width: 75vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: 2rem;  
}

.carousel-item {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: auto;
    object-fit: contain;
    /* border: solid 5pt;
    border-radius: 5%;
border-color: #95F8F1; */
}



.carouselImg {
    height: 100%;
    width: 100%;
object-fit: contain;
}

.artLabel {
    color: rgb(148,224,255);
    background-color: rgb(207,58,165);
    border-radius: 1rem;
    width: 35%;
}

.artDescription {
    color: rgb(148,224,255);
    background-color: rgb(207,58,165);
    border-radius: 1rem;
    width: 60%;
}